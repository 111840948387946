<template>
  <div class="card-container">
    <header @click="showCard" :class="{ pointer: collaps }" class="card-header">
      <!-- @slot card header -->
      <slot name="header" />
      <v-svg class="icon-header" v-if="collaps" name="chevron-top" size="13px" :class="{ rotate: !show }" />
    </header>

    <transition name="card-container" v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:before-leave="beforeLeave" v-on:leave="leave">
      <div class="body" v-show="collaps ? show : true">
        <div class="card-body">
          <!-- @slot card content -->
          <slot name="body"></slot>
        </div>
        <footer class="card-footer">
          <!-- @slot card footer -->
          <slot name="footer" />
        </footer>
      </div>
    </transition>
  </div>
</template>

<script>
  import VSvg from '@/components/base/Svg'

  export default {
    name: 'Card',
    components: {
      VSvg
    },
    props: {
      /**
       * card collaps
       */
      collaps: {
        type: Boolean,
        default: false
      },
      isOpen: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        show: this.isOpen
      }
    },

    methods: {
      beforeEnter: function (el) {
        el.style.height = '0'
      },
      enter: function (el) {
        el.style.height = el.scrollHeight + 'px'
      },
      beforeLeave: function (el) {
        el.style.height = el.scrollHeight + 'px'
      },
      leave: function (el) {
        el.style.height = '0'
      },
      showCard() {
        this.show = !this.show
        this.$emit('showCard', this.show)
      }
    }
  }
</script>

<style lang="scss">
  .card-container {
    background-color: white;
    border-radius: 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 1em;
    /* padding-bottom: 1rem;*/

    .body {
      background-color: #fff;
      border-radius: 16px;
      transition: 150ms ease-out;
      padding: 10px 32px;
    }

    .pointer {
      cursor: pointer;
      .icon-header {
        transform: rotate(0deg);
        transition-duration: 0.3s;
      }
      .icon-header.rotate {
        transform: rotate(180deg);
        transition-duration: 0.3s;
      }
    }
    .card-header {
      width: 100%;
      padding: 0rem 1.5rem 0 1.5rem;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
</style>
