<template>
  <div class="autocomplete">
    <div class="flex-start">
      <div v-if="!isSearchMode" class="mr-1 search-mode">
        <label for="inputField" class="text-field-label text-bold"> {{ title }} <span v-if="error.length" class="error">*</span> </label>
      </div>
      <div>
        <v-chip v-if="error.length" :label="error" :icon="'warning'" :color="'error'" :is-small="true" />
      </div>
    </div>
    <div
      :class="[
        isSearchMode ? 'autocomplete-search autocomplete-search-mode' : 'autocomplete-search',
        isLoading ? 'autocomplete-search-disabled' : ''
      ]"
    >
      <input
        v-model="tempSearch"
        class="autocomplet-search-input"
        type="text"
        :disabled="disabled"
        :placeholder="placeholder"
        @click="reOpenResults"
        @keyup="emitOrNavigate"
      />
    </div>
    <div v-if="isOpen && results.length" class="autocomplete-results" ref="targetElement">
      <div
        v-for="(result, i) in results"
        :key="i"
        class="autocomplete-result flex justify-space"
        :class="{ 'is-active': i === arrowCounter - 1 }"
        @click="setResult(result)"
      >
        {{ result.mail }}
        <v-svg class="icon ml-1 mr-1" name="chevron-right" :size="'10px'" />
      </div>
    </div>
  </div>
</template>

<script>
  import VSvg from '@/components/base/Svg'
  import {COUNTRY_CODES} from "@/utils/country";
  import VChip from "@/components/Chip";

  export default {
    name: 'AutocompletEmail',
    components: {
      VSvg,
      VChip
    },
    props: {
      /**
       * currentSearch component
       */
      currentSearch: {
        type: String
      },
      /**
       * Field disabled true or false
       */
      disabled: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      /**
       * Input placeholder
       */
      placeholder: {
        type: String,
        default: function () {
          return this.$t('search')
        }
      },
      /**
       * Search items
       */
      items: {
        type: Array,
        default: () => []
      },
      /**
       * Is Search Mode
       */
      isSearchMode: {
        type: Boolean,
        default: false
      },
      /**
       * title
       */
      title: {
        type: String,
        default: function () {
          return this.$t('contactForm.email')
        }
      },
      /**
       * Error after submit
       */
      submitError: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        isOpen: false,
        isOpen2: true,
        results: [],
        search: {},
        arrowCounter: 0,
        tempSearch: this.currentSearch
      }
    },
    watch: {
      currentSearch(val) {
        this.tempSearch = val
      },
      items(val) {
        this.results = this.items

        if (this.items.length && this.isOpen2) {
          this.isOpen = true
          this.isOpen2 = false
        } else {
          this.isOpen = false
          this.isOpen2 = true
        }
        this.tempSearch = this.currentSearch
      }
    },
    computed: {
      error() {
        return this.submitError
      },
    },

    methods: {
      onChange() {
        this.$emit('input', this)
        this.filterResults()
        this.isOpen = false
      },

      filterResults() {
        this.results = this.items.filter(item => {
          return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        })
      },
      setResult(result) {
        this.search = result
        this.isOpen2 ? (this.isOpen2 = false) : (this.isOpen = false)
        this.$emit('selected', this.search)
        this.$emit('input', this.search.mail)
      },
      onArrowDown(evt) {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1
        }
      },
      onEnter() {
        this.search = this.results[this.arrowCounter - 1]
        this.isOpen = false
        this.arrowCounter = 0
        this.$emit('selected', this.search)
        this.$emit('input', this.search.mail)
      },
      emitOrNavigate($event) {
        switch ($event.keyCode) {
          case 'ArrowUp':
            this.onArrowUp()
            break
          case 'ArrowDown':
            this.onArrowDown()
            break
          default:
            this.$emit('key-pressed', this.tempSearch)
        }
      },
      reOpenResults() {
        if (this.results.length) {
          this.isOpen = true
        }
      },
      handleClickOutside(event) {
        const targetElement = this.$refs.targetElement
        if (!targetElement?.contains(event.target)) {
          this.isOpen = false
          this.isOpen2 = true
        }
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside)
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';

  .autocomplete {
    width: initial;
    min-width: 200px;
    .flex-start {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }
  }

  .autocomplete-search-disabled::before {
    content: '';
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0;
    width: 30px;
    background-size: 20px 20px;
    background: url('../assets/loading.gif') no-repeat right center/30px !important;
  }

  .autocomplete-search {
    border-radius: 8px 8px 0px 0px;
    // width: 100%;
    position: relative;
  }
  .autocomplete-search-mode:before {
    content: '';
    position: absolute;
    left: 1rem;
    top: 0;
    bottom: 0;
    width: 2rem;
    background: url('../assets/svg/search.svg') no-repeat right center;
  }

  .autocomplete-search-mode {
    input {
      background-color: $lightblue !important;
      color: $blue;
      padding-left: 4rem;
      height: 4.4rem;
      outline: none;

      &::placeholder {
        color: $blue;
      }
    }
  }

  .autocomplete-search input {
    height: 31px;
    width: -webkit-fill-available;
    font-size: 12px;
    border: 0;
    outline-width: 0;
    bottom: 0;
    color: $blue;
    background-color: transparent;

    border-bottom: 1px solid #edeffc;
    transition: border 200ms ease-in;
  }

  .autocomplet-search-input:disabled {
    color: $lightgrey;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    background-color: white;
    z-index: 1;
  }

  .autocomplete-result {
    height: 36px;
    list-style: none;
    text-align: left;
    padding: 4px 0 4px 1em;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: $lightblue;
  }

  .right-inner-addon {
    position: relative;
  }
  .right-inner-addon input {
    padding-right: 30px;
  }
</style>
